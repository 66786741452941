<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-if="hasDetailsInfo">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="img-vessel">
                <img :src="hasVesselImg" alt="VESSEL" srcset="" />
              </div>
              <div class="title-vessel">
                <strong> {{ titleDataSheet }} </strong>
              </div>
              <div class="info-vessel">
                <div class="vessel">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ $t('label.type') }}: </strong
                          >{{ getDato(details.TpVesselName) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.country') }}: </strong
                          >{{ getDato(details.CountryName) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.IMO') }}: </strong>{{ getDato(details.Imo) }}
                        </td>
                        <td class="limit-field-30 p-0">
                          <table>
                            <tr>
                              <td>
                                <strong>MMSI: </strong
                                >{{ getDato(details.Mmsi) }}
                              </td>
                              <td>
                                <strong>CALL SIGN: </strong>
                                {{
                                  getDato(details.CallSign)
                                    ? getDato(details.CallSign)
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>LOA:</strong> {{ getDato(details.Loa) }} (MTR)
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.bowDraft') }}: </strong
                          >{{ details.DraftBow && details.DraftBow != '' ? `${getDato(details.DraftBow)} (MTR)` : '' }} 
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.summerDWT') }}: </strong
                          >{{ getDato(details.SummerDwt) }}
                        </td>
                        <td class="limit-field-30">
                          <strong>{{ $t('label.LWL') }}: </strong>{{ getDato(details.Lwl) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t('label.sternDraft') }}: </strong
                          >{{ details.DraftStern && details.DraftStern != '' ? `${getDato(details.DraftStern)} (MTR)` : ''}} 
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.gross') }}: </strong>{{ details.Gross && details.Gross != '' ? `${NumberFormat(getDato(details.Gross), 2)} (TON)`: '' }} 
                        </td>
                        <td class="limit-field-20">
                          <strong>TEUS: </strong>{{ getDato(details.Teus) ? getDato(details.Teus) : "-" }}
                        </td>
                        <td class="limit-field-30">
                          <strong>{{ $t('label.fuelOilCapacity') }}: </strong
                          >{{ details.FuelOilCapacity && details.FuelOilCapacity != '' ?  `${getDato(details.FuelOilCapacity)} (M³)` : '' }} 
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="transversal-view">
                  <transversal-view
                    :vessel-id="getDato(details.VesselId)"
                    :VesselType="getDato(details.TpVesselId)"
                    pdf
                    :tooltip="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 1 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>
      <!-- Tablas -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="vessel-tables">
              <div class="vessel-info">
                <strong> {{ `${$t("label.crane")}S` }} </strong>
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t("label.name") }}</th>
                      <th class="text-right">{{ $t("label.type") }}</th>
                      <th class="text-right">{{ $t("label.maxWeight") }}(TON)</th>
                      <th class="text-right">{{ $t("label.maxHeigth") }}(MTR)</th>
                      <th class="text-center">{{ $t("label.operativa") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="hasCranes">
                    <tr v-for="(crane, index) in getCranes" :key="index">
                      <td>{{ crane.CraneName }}</td>
                      <td class="text-right">{{ crane.TpCraneName }}</td>
                      <td class="text-right">{{ formatNumber(crane.MaxWeigth) }}</td>
                      <td class="text-right">{{ formatNumber(crane.MaxHeigth) }}</td>
                      <td class="text-center">
                        {{ crane.FgOperational ? `${$t("label.yes")}` : "NO" }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <td colspan="5" style="width:100%">&nbsp;</td>
                  </tbody>
                </table>
              </div>

              <div class="vessel-info">
                <strong> {{ `${$t("label.hold")}S` }} </strong>
                <table>
                  <thead>
                    <tr>
                      <th style="width:15%">{{ $t("label.name") }}</th>
                      <th v-if="vesselType" class="text-right">{{ $t("label.bale") }}</th>
                      <th v-if="vesselType" class="text-right">{{ `${$t("label.baleOnGrain")}(M³)` }}</th>
                      <th v-if="vesselType" class="text-right">{{ `${$t("label.baleOnDeck")}(M³)` }}</th>
                      <th class="text-center">{{ $t("label.hatch") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="hasHolds">
                    <tr v-for="hold in getHolds" :key="hold.VesselHoldId">
                      <td>{{ hold.VesselHoldName }}</td>
                      <td v-if="vesselType" class="text-right">{{ formatNumber(hold.Bale) }}</td>
                      <td v-if="vesselType" class="text-right">{{ formatNumber(hold.BaleOnGrain) }}</td>
                      <td v-if="vesselType" class="text-right">{{ formatNumber(hold.BaleOnDeck) }}</td>
                      <td class="text-center">
                        <span
                          :class="vesselType ? 'hatch-info' : '' "
                          v-for="(hatch, index) in hold.HatchCoverJson"
                          :key="index"
                          >
                            {{ `${hatch.HatchCoverName} - ${hatch.HatchCoverLocName}${coma(hold.HatchCoverJson, index)} `}}
                          </span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <td colspan="5" style="width:100%">&nbsp;</td>
                  </tbody>
                </table>
              </div>

              <div class="vessel-info" v-if="hasBays">
                <strong> {{ `BAYS` }} </strong>
                <table>
                  <thead>
                    <tr>
                      <th>CONTAINER INTAKE</th>
                      <th class="text-right">TEUS</th>
                      <th class="text-right">{{ `MAX ${$t("label.row")}` }}</th>
                      <th class="text-right">{{ $t("label.stacking") }}</th>
                      <th class="text-right">BAYS</th>
                    </tr>
                  </thead>
                  <tbody v-if="hasBaysIntake">
                    <tr>
                      <td style="width:15%">{{ $t("label.inHold") }}</td>
                      <td class="text-right">{{ `${getUnderDeck.Teus}` }}</td>
                      <td class="text-right">{{ `${getUnderDeck.MaxRow}` }}</td>
                      <td class="text-right">
                        {{ `${$t("label.upTier")} ${getUnderDeck.UpTier}` }}
                      </td>
                      <td class="text-right" style="width:40%">
                        <div class="intake-bays">
                          <span
                            v-for="(bay, index) in getUnderDeck.BaysUnderDeckJson"
                            :key="index"
                          >{{ `${bay.BayCode}${getUnderDeck.BaysUnderDeckJson.length-1 != index ? '-':''}` }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style="width:15%">{{ $t("label.onDeck2") }}</td>
                      <td class="text-right">{{ `${getToDeck.Teus}` }}</td>
                      <td class="text-right">{{ `${getToDeck.MaxRow}` }}</td>
                      <td class="text-right">
                        {{ `${$t("label.upTier")} ${getToDeck.UpTier}` }}
                      </td>
                      <td class="text-right" style="width:40%">
                        <div class="intake-bays">
                          <span
                            v-for="(bay, index) in getToDeck.BaysDeckToJson"
                            :key="index"
                          >{{ `${bay.BayCode}${getToDeck.BaysDeckToJson.length-1 != index ? '-':''}` }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <td colspan="5" style="width:100%">&nbsp;</td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 2 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>
      <!-- Bays -->
      <section class="pdf" v-if="hasBays">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="bays-tables">
              <div class="bays-info">
                <strong> {{ $t("label.reeferSockets") }} </strong>
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t("label.deck") }}</th>
                      <th>{{ `${$t("label.hold")}S` }}</th>
                      <th>TOTALS</th>
                    </tr>
                  </thead>
                  <tbody v-if="hasReefers">
                    <tr v-for="(reefer, index) in getReefers" :key="index">
                      <td>{{ `${reefer.Deck} SOCKETS` }}</td>
                      <td>{{ `${reefer.Holds} SOCKETS` }}</td>
                      <td>{{ `${reefer.Total} SOCKETS` }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <td colspan="5" style="width:100%">&nbsp;</td>
                  </tbody>
                </table>
              </div>
              <div class="bays-info">
                <strong> {{ $t("label.capacity") }} 20 FT </strong>
                <table v-for="(bays, index) in formatBaysTwenty" :key="index">
                  <tr class="cabecera">
                    <th>BAYS</th>
                    <td v-for="bay in bays" :key="bay.VesselBayId">{{bay.BayCode}}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("label.quantity") }}</th>
                    <td v-for="bay in bays" :key="bay.VesselBayId">{{bay.Cant}}</td>
                  </tr>
                </table>
                <table class="totales">
                  <tr class="cabecera">
                    <th class="text-center">{{ $t("label.totalQuantity") }}</th>
                    <td>{{ getTotalTwenty }}</td>
                  </tr>
                  <tr class="cabecera">
                    <th class="text-center">TEUS</th>
                    <td>{{ getTeusTwenty }}</td>
                  </tr>
                </table>
              </div>
              <div class="bays-info">
                <strong> {{ $t("label.capacity") }} 40 FT </strong>
                <table v-for="(bays, index) in formatBaysForty" :key="index">
                  <tr class="cabecera">
                    <th>BAYS</th>
                    <td v-for="bay in bays" :key="bay.VesselBayId">{{bay.BayCode}}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("label.quantity") }}</th>
                    <td v-for="bay in bays" :key="bay.VesselBayId">{{bay.Cant}}</td>
                  </tr>
                </table>
                <table class="totales">
                  <tr class="cabecera">
                    <th class="text-center">{{ $t("label.totalQuantity") }}</th>
                    <td>{{ getTotalForty }}</td>
                  </tr>
                  <tr class="cabecera">
                    <th class="text-center">TEUS</th>
                    <td>{{ getTeusForty }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 3 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TransversalView from '@/pages/buque/bays/transversal-view';
import DataStorage from "@/_helpers/localStorage";
import { NumberFormater } from '@/_helpers/funciones';

//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function NumberFormat(number, decimal) {
  if(decimal == 0){
    let format = new Intl.NumberFormat('de-DE').format(number)
    return format;
  }
  return NumberFormater.formatNumber(number, decimal);
}

function getDato(value) {
  if (!this.details) return "";

  return value ? value : "";
}

function makeChunks(arr, chunkSize) {
  var R = [];
  for (var i = 0; i < arr.length; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
    
  return R;
}
function formatBaysTwenty() {
  if(!this.details) return [];

  return makeChunks(this.details.Capacity20FeetJson.filter((item) => item.VesselBayId != null), 25);
}
function formatBaysForty() {
  if(!this.details) return [];

  return makeChunks(this.details.Capacity40FeetJson.filter((item) => item.VesselBayId != null), 25);
}
function formatNumber(number) {
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace('.', ',');

  return format.replace(/\d(?=(\d{3})+\,)/g, '$&.');
}

//Computeds:
function vesselType() {
  if (this.condition=='BULK CARRIER'){
    return true;
  }
  else{
    return false;
  }
}

function coma(hatch, index) {
  let length = hatch.length;
  if (length == index+1){
    return '';
  }
  else{
    return `,`;
  }
  
}

function hasDetailsInfo() {
  return this.details ? true : false;
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function hasVesselImg() {
  return `${this.$store.getters["connection/getBase"]}${
    this.vesselRoute ? this.vesselRoute.replace("Public/", "", null, "i") : ""
  }`;
}
function footerText() {
  return "";
}
function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromCompany() {
  return this.user ? `${this.user.CompanyName} ${this.user.CompanyRif}` : "";
}
function companyLocation() {
  return this.user ? `${this.user.FiscalAddress}` : "";
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function titleDataSheet() {
  if (!this.details) return `${this.$t("label.vesselSheet")}: `;

  let name = this.details.VesselName,
    imo = this.details.Imo;

  return `${this.$t("label.vesselSheet")}: ${name} - ${imo}`;
}

function hasCranes() {
  return this.details &&
    Array.isArray(this.details.CranesJson) &&
    this.details.CranesJson.length != 0
    ? true
    : false;
}
function getCranes() {
  if (!this.hasCranes) return [];

  return this.details.CranesJson;
}
function hasHolds() {
  return this.details &&
    Array.isArray(this.details.HoldsJson) &&
    this.details.HoldsJson.length != 0
    ? true
    : false;
}
function getHolds() {
  if (!this.hasHolds) return [];

  return this.details.HoldsJson;
}
function hasBaysIntake() {
  return this.details &&
    Array.isArray(this.details.UnderDeckJson) && Array.isArray(this.details.DeckToJson) &&
    this.details.UnderDeckJson.length != 0 && this.details.DeckToJson.length != 0
    ? true
    : false;
}
function getUnderDeck() {
  if (!Array.isArray(this.details.UnderDeckJson) && this.details.UnderDeckJson.length == 0) return {
      UpTier: 0,
      MaxRow: 0,
      Teus: 0,
      BaysUnderDeckJson: []
  };

  return this.details.UnderDeckJson[0];
}
function getToDeck() {
  if (!Array.isArray(this.details.DeckToJson) && this.details.DeckToJson.length == 0) return {
      UpTier: 0,
      MaxRow: 0,
      Teus: 0,
      BaysDeckToJson: []
  };

  return this.details.DeckToJson[0];
}
function hasReefers() {
  return this.details &&
    Array.isArray(this.details.ReeferSocketsJson) &&
    this.details.ReeferSocketsJson.length != 0
    ? true
    : false;
}
function getReefers() {
  if (!this.hasReefers) return [];

  return this.details.ReeferSocketsJson;
}
function hasBays() {
  return this.details &&
    Array.isArray(this.details.Capacity20FeetJson) &&
    this.details.Capacity20FeetJson.length != 0 && Array.isArray(this.details.Capacity40FeetJson) &&
    this.details.Capacity40FeetJson.length != 0 && this.details.TpVesselName == 'CONTAINER SHIP'
    ? true
    : false;
}

function getTotalTwenty() {
  if (!this.details) return 0;

  return this.details.Capacity20FeetJson[this.details.Capacity20FeetJson.length-1].Cant;
}
function getTeusTwenty() {
  if (!this.details) return 0;

  return this.details.Capacity20FeetJson[this.details.Capacity20FeetJson.length-1].Teus;
}
function getTotalForty() {
  if (!this.details) return 0;

  return this.details.Capacity40FeetJson[this.details.Capacity40FeetJson.length-1].Cant;
}
function getTeusForty() {
  if (!this.details) return 0;

  return this.details.Capacity40FeetJson[this.details.Capacity40FeetJson.length-1].Teus;
}
function totalPages() {
  return this.details.TpVesselName == 'CONTAINER SHIP' ? 3 : 2;
}

export default {
  name: "transversal-view-pdf",
  props: {
    details: {
      type: Object,
      default: null,
    },
    vesselRoute: {
      type: String,
      default: "",
    },
    condition: {
      type: String,
      default: "",
    },
  },
  mixins: [],
  components: {
    TransversalView
  },
  data,
  created,
  methods: {
    NumberFormat,
    coma,
    getDato,
    formatNumber,
  },
  computed: {
    vesselType,
    footerText,
    currentDateTime,
    titleFromCompany,
    companyLocation,
    hasLogo,
    hasVesselImg,
    loginName,
    hasDetailsInfo,
    titleDataSheet,
    hasCranes,
    hasHolds,
    hasBaysIntake,
    hasReefers,
    getCranes,
    getHolds,
    getUnderDeck,
    getReefers,
    getTotalTwenty,
    getTeusTwenty,
    getTotalForty,
    getTeusForty,
    formatBaysTwenty,
    formatBaysForty,
    hasBays,
    getToDeck,
    totalPages,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {},
};
</script>

<style scoped src="../../planificacion-estiba/archivos/pdf/pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */

.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 600px;

  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 1.1rem;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  height: 75%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
}
.vessel > table,
.vessel > table td {
  border: 1px solid #000;
}
.vessel > table td {
  padding: 2px 5px;
  width: 25%;
}
.limit-field-20 {
  width: 20% !important;
}
.limit-field-30 {
  width: 30% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.info-content .info-vessel .transversal-view {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  overflow: hidden;
}

/* *************************************************** */
/* Tablas de Info */
/* *************************************************** */
.pdf .body .general-info .vessel-tables {
  width: 95%;
  height: 600px;

  display: flex;
  flex-direction: column;
  /* border: 1px solid #000; */
}
.vessel-tables .vessel-info {
  width: 100%;
  margin-bottom: 20px;
}
.vessel-tables .vessel-info table {
  border-collapse: collapse;
  margin-top: 10px;
  width: 100%;
}
.vessel-tables .vessel-info > table,
.vessel-tables .vessel-info > table th,
.vessel-tables .vessel-info > table td {
  border: 1px solid #000;
}
.vessel-tables .vessel-info > table th {
  background-color: #95b3d7;
  text-align: left;
}
.vessel-tables .vessel-info > table th,
.vessel-tables .vessel-info > table td {
  padding: 0 5px;
}

.vessel-tables .vessel-info .hatch-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vessel-tables .vessel-info .intake-bays{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* *************************************************** */
/* Tablas de Bays */
/* *************************************************** */
.pdf .body .general-info .bays-tables {
  width: 95%;
  height: 600px;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* border: 1px solid #000; */
}
.bays-tables .bays-info {
  /* background-color: greenyellow; */
  width: 100%;
  margin-bottom: 25px;
}
.bays-tables .bays-info table {
  border-collapse: collapse;
  margin-top: 15px;
  /* width: 100%; */
}
.bays-tables .bays-info > table,
.bays-tables .bays-info > table th,
.bays-tables .bays-info > table td {
  border: 1px solid #000;
  text-align: center;
}
.bays-tables .bays-info > table th {
  background-color: #8db3e2;
  text-align: left;
}
.bays-tables .bays-info > table th,
.bays-tables .bays-info > table td {
  padding: 0 4px;
}
.bays-tables .bays-info > table tr.cabecera td {
  background-color: #f2f2f2;
}
.bays-tables .bays-info > table.totales th,
.bays-tables .bays-info > table.totales td {
  padding: 5px 10px;
}
</style>